.box {
  width: 13rem;
  height: 4.3rem;
  white-space: nowrap;
  padding: 1.5rem 1rem 1.5rem 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  background-color: white;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  color: #26890d;
}
