.prompt_Container {
  border-radius: 0.375rem;
  border: 0.063rem solid #dbd9d9;
  max-width: 25rem;
  padding: 1.25rem;
}
.promptbuttonClicked {
  background: var(--CTA, #26890d);
  border: 0.063rem solid var(--CTA, #26890d);
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  color: #ffff;
}
.finalpromtbuttonClicked {
  border: 0.063rem solid var(--CTA, #26890d);
  font-family: Open Sans;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  color: #26890d;
}

.editArea {
  font-weight: 600;
  width: 100%;
  padding-right: 1rem;
  min-height: 17.75rem;
}
.displayedPromt {
  min-height: 17.75rem;
}
