.revise-container {
  padding-bottom: 1.25rem;
  flex: 80%;
  width: 100%;
}

.revise-button-productDescription {
  font-weight: 600;
  display: flex;
  width: 100%;
  height: 2.813rem;
  padding: 0.5rem 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
}
