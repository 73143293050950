.heading {
  text-align: center;
  font-size: 3rem;
}

.align-container {
  display: flex;
  width: 100%;
  align-items: center;
  /* padding-top: 30px;
  padding-bottom: 30px; */
}

.dice-icon {
  flex: 1;
  margin-bottom: 2px;
}

.subHead {
  margin: 60px;
  margin-bottom: 2px;
  font-size: 2.3rem;
  flex: 1;
  display: flex;
  justify-content: center;
}

.revision {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex: 5; */
}

.text-label {
  color: #848484;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/* .css-1wafggb-MuiInputBase-root-MuiOutlinedInput-root {
  height: 46px !important;
} */

.css-1wafggb-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #dfdfdf !important;
  border-width: 2px;
}

.regenerate-button {
  padding-top: 25px;
}

/* .css-17yksi1-MuiInputBase-root-MuiOutlinedInput-root {
  height: 46px !important;
  border-radius: 8px !important;
} */
