.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-bg-image {
  background-image: url("../assets/loginBackground.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-height: 700vh; */
  /* padding-left: 10rem; */
}

.sub-container {
  width: 1300px;
  height: 700px;
  /* padding-left: 15px; */
  /* padding-top: 0; */
  border-radius: 18px;
  background-color: white;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-box {
  width: 1140px;
  height: 650px;
  background-color: white;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-img {
  width: 500px;
  height: 500px;
  background-image: url("../assets/image1.png");
  border: none;
}

.heading {
  font-size: 2rem;
  font-weight: 500;
}

.text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

p {
  margin-top: 20px;
  /* padding: 5px; */
}

.disclaimer {
  /* width: 27rem; */
  
  margin-top: 1rem;
  background-color: #fffeed;
  border-radius: 0.75rem;
}
