.card {
  width: 100%;
  height: 19.7rem;
  background-color: white;
  border-radius: 0.4rem;
}
.content-text {
  width: 100%;
  height: 50vh;
}
.content-img {
  overflow-y: scroll;
  height: 30rem;
  width: 8.43rem;
}
#image {
  height: 9.43rem;
  width: 9.43rem;
}
.delete-resume {
  height: 2rem;
}
