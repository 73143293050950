.header-tabs {
  margin-left: 0rem !important;
  padding-right: 0 !important;
}
/* .top-header {
  background-image: url(./assets/TopHeader.jpg);
} */

.header-Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
}
.tab-text {
  margin-left: 0.5rem;
}


.header-logout {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MuiTab-root {
  /* flex: 1; */
  font-size: 1vw;
  min-width: max-content;
  width: max-content;
}
button.MuiTab-root {
  min-height: max-content;
  height: "2rem";
}
.css-1ujnqem-MuiTabs-root {
  color: black !important;
}

.css-12p34if-MuiButtonBase-root-MuiTab-root {
  min-width: max-content !important;
  flex-shrink: 1 !important;
}

.css-f5fszp-MuiButtonBase-root-MuiTab-root {
  flex-shrink: 1 !important;
}

.css-28klzc-MuiButtonBase-root-MuiTab-root {
  min-height: 3.125rem !important;
}

.css-m84l35-MuiTabs-root {
  width: 90% !important;
}

.css-heg063-MuiTabs-flexContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  justify-content: space-around !important;
}

.css-10ypwkz {
  overflow: hidden;
  min-height: 3rem;
  display: flex;
  width: 90% !important;
}

.css-k008qs {
  display: flex !important;
  justify-content: space-around !important;
}

@media (max-width: 1346px) {
  .MuiTab-root {
    font-size: 1vw !important;
  }
  .save-tab {
    width: 1.5rem;
    height: 1.4rem;
  }
  .header-logout {
    width: 2rem;
    height: 2rem;
    margin-top: 0.5rem;
  }
  .header-Icon {
    width: 1.5rem;
    height: 1.4rem;
    margin-top: 0.8rem;
  }
}
.MuiTabScrollButton-horizontal .css-ptiqhd-MuiSvgIcon-root{
      color: green !important;
      font-size: 1.9rem !important;
      border: 0.0625rem solid green;
      border-radius: 50%;   
}

@media (max-width: 1040px) {
  .MuiTab-root {
    font-size: 0.8vw !important;
  }
  .save-tab {
    width: 0.8rem;
    height: 0.8rem;
  }
  .header-logout {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.8rem;
  }
  .header-Icon {
    width: 0.7rem;
    height: 0.7rem;
    margin-top: 1.1rem;
  }
}
@media (max-width: 920px) {
  w .MuiTab-root {
    font-size: 0.4vw !important;
  }
  .save-tab {
    width: 0.7rem;
    height: 0.7rem;
  }
  .header-logout {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.8rem;
  }
  .header-Icon {
    width: 0.6rem;
    height: 0.6rem;
    margin-top: 1.2rem;
  }
}
@media (max-width: 884px) {
  .MuiTab-root {
    font-size: 0.4vw !important;
  }
  .save-tab {
    width: 0.6rem;
    height: 0.6rem;
  }
  .header-logout {
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 0.8rem;
  }
  .header-Icon {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 1.2rem;
  }
}
@media (max-width: 884px) {
  .MuiTab-root {
    font-size: 0.3vw !important;
    width: max-content;
  }
  .save-tab {
    width: 0.4rem;
    height: 0.4rem;
  }
  .header-logout {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.9rem;
  }
  .header-Icon {
    width: 0.4rem;
    height: 0.4rem;
    margin-top: 1.3rem;
  }
}


.wrapper .icon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 7.5rem;
  display: flex;
  align-items: center;
}

.icon:first-child {
  left: 0;
  display: none;
  background: linear-gradient(90deg, #fff 70%, transparent);
}

.icon:last-child {
  right: 0;
  justify-content: flex-end;
  background: linear-gradient(-90deg, #fff 70%, transparent);
}

.icon  {
  width: 4.375rem;
  height: 4.375rem;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: center;
  line-height: 3.438rem;
  border-radius: 50%;
}

.icon i:hover {
  background: #efedfb;
}

.icon:first-child i {
  margin-left: 0.938rem;
}

.icon:last-child i {
  margin-right: 0.938rem;
}

.wrapper .tabs-box {
  display: flex;
  gap: 0.75rem;
  list-style: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.tabs-box.dragging {
  scroll-behavior: auto;
  cursor: grab;
}

.tabs-box .tab {
  cursor: pointer;
  font-size: 1.18rem;
  white-space: nowrap;
  background: #f5f4fd;
  padding: 0.813rem 1.25rem;
  border-radius: 1.875rem;
  border: 0.063rem solid #d8d5f2;
}

.tabs-box .tab:hover {
  background: #efedfb;
}

.tabs-box.dragging .tab {
  user-select: none;
  pointer-events: none;
}

