.page {
  font-family: "Open Sans";
}

.page > p {
  font-weight: 100;
}
.drafts {
  width: 100%;
  height: 100%;
  margin-left: 1rem;
}
.no-drafts {
  width: 100%;
  height: 61rem;
  background-color: white;
  margin-left: 1rem;
  border-radius: 0.4rem;
}
.container {
  width: 100%;
  height: 100%;
  margin: 4rem;
}
