.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(111, 111, 111, 0.5);
  z-index: 9;
}

.chatButton {
  margin-top: 1px;
}

.chat-container {
  position: fixed;
  top: 0;
  right: -376px;
  width: 376px;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  transition: all 0.4s linear;
  z-index: 40;
}

.open {
  /* opacity: 1;
    visibility: visible; */
  right: 0;
  transition: all 0.4s linear;
  /* transform: translateX(0); */
}

.close {
  position: fixed;
  top: 0;
  right: -376px;
  width: 376px;
  height: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  /* opacity: 0;
    visibility: hidden; */
  transition: all 0.4s linear;
  z-index: 40;
  /* right: 0; */
  /* transform: translateX(100%); */
  /* transition: transform 1s; */
}

.file-container {
  overflow: auto;
  height: 98%;
}

.chat-heading {
  background-color: #26890d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancel-chat-button {
  margin-right: 2px;
  font-size: 16px;
}

.file-list {
  border-radius: 6px;
  border: 1px dashed rgba(203, 122, 0, 0.4);
  background: #fcf3e8;
  padding: 19px;
}
.file-list-completedProgress {
  border-radius: 8px;
  border: 1px solid var(--CTA, #26890d);
  background: #f7f9f3;
  padding: 19px;
}
.file-list-failed {
  border-radius: 8px;
  border: 1px solid #ec9d98;
  background: #f9f3f3;
  padding: 19px;
}
.file-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.file-icon {
  width: 25px;
  height: 30px;
  margin-right: 9px;
  margin-left: -4px;
}

.download-File-Button {
  width: 20px;
  margin-left: 2px;
}

.progressbar-container {
  display: flex;
  margin-top: 10px;
  height: 11px;
  overflow: hidden;
  border-radius: 20px 20px 20px 20px;
}

.progressbar {
  width: 100%;
  height: 100%;
}

.file-uploaded {
  color: #ec8915;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.file-uploaded-successfully {
  color: var(--CTA, #26890d);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 107.143% */
  margin-bottom: 14px;
}
.failed-status-text {
  color: #ec9d98;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 107.143% */
  margin-bottom: 14px;
}
.file-name-failed {
  color: #ec9d98;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 107.143% */
  margin-bottom: 14px;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c6c6c6;
  border-radius: 20px;
}

.file-name {
  color: #000;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 2px;
}

progress[value] {
  --w: 300px;
  --color: #e89623 var(--w);
  --background: #e4e4e4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  border-radius: 10em;
  background: var(--background);
}

progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
}

progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color);
}

progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
}

.reload-icon {
  display: inline-block;
}

.glow img {
  animation: glow 1s ease-in-out infinite;
}

@keyframes glow {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.7);
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.7);
  }
}
