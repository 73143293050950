.custom-dropdown {
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  background-color: #fff;
  width: 200px;
}

.custom-dropdown::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border-radius: 0.25rem;
}

.custom-dropdown:focus {
  outline: none;
  border-color: #26890d;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.custom-dropdown option {
  border-radius: 2rem;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.filters {
  display: flex;
  align-items: center;
}

.download-button-position {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.downloadButton {
  background-color: #26890d;
}

.Admin-heading {
  margin-left: 40%;
  font-size: 2rem;
  font-weight: 700;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.popup-container {
  position: relative;
  display: inline-block;
}

.popup-link {
  text-decoration: none;
  color: #333;
}

.popup {
  position: absolute;
  background-color: #f0e7e7;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  display: none;
}

.popup-link:hover .popup {
  display: block;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .css-141on25-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  width: 170px;
} */
div.date-picker-name ~ div > div {
  height: 2.5rem;
}

.css-1uvydh2 {
  height: 10px;
}

.css-s698ob {
  height: 3.5rem;
}
.datefield {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.select-fields {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1427px) {
  .datefield {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1005px) {
  .select-fields {
    flex-direction: column;
    justify-content: center;
  }
}
