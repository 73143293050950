.base {
  margin: 0;
  padding: 0;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: "center";
  align-items: "center";
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-height: 700vh; */
  /* padding-left: 10rem; */
}

.sub-container-intermediate {
  width: 600px;
  height: 250px;
  border-radius: 18px;
  background-color: white;
  justify-content: center;
  justify-items: center;
}

.logout {
  position: relative;
  top: 10px;
  left: 565px;
  color: #26890d;
}
