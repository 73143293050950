.magnify{
    position: relative;
    z-index: 1;
    bottom: 12.6rem;
    left: 0;
    padding: 0;
    color: grey;
    transform: translateX(-95%);
    

}
.locked{
    position: relative;
    z-index: 1;
    bottom: 2.1rem;
    left: 11.1rem;
    padding: 0;
    color: grey;
    transform: translateX(-30%);

}