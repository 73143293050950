.downloadButton_fill {
  padding: 0.188rem 0.5rem 0.188rem 0.625rem;
  border-radius: 0.375rem;
  background-color: #ffff;
  display: flex;
  align-items: center;
  color: var(--CTA, #26890d);
  font-size: 0.875rem;
  font-weight: 600;
}

.dropdown_container {
  margin-top: 0.625rem;
  border-radius: 0.375rem;
  border: 0.063rem;
  background-color: #ffff;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  color: #676767;
  font-weight: 400;
  font-size: 1rem;
}
.dropdown_layout {
  border-bottom: 0.063rem solid #dbd9d9;
}
