.language_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.multipleLanguageSelect_popup {
  background-color: #fff !important;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"] {
  accent-color: green;
}
