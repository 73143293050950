.css-14t61ht-MuiButtonBase-root-MuiButton-root {
  position: static !important;

  position: static !important;
  padding: 2% 45% !important;
  width: 0px !important;
}
.sidebar {
  width: 25rem;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root{
  box-shadow: none !important;
}

.css-nh5ju3.Mui-expanded{
  margin: 0px !important;
}

/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  width: 100% !important;
} */
/* .css-1fmf65h-MuiDrawer-docked .MuiDrawer-paper {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  overflow-x: hidden !important;
} */
/* .css-m84l35-MuiTabs-root .Mui-selected {
  height: -1px !important;
} */
/* @media (min-width: 1350px) {
  .sidebar {
    width: ;
  }
} */
@media (max-width: 1346px) {
  .sidebar {
    width: 22rem;
  }
}
@media (max-width: 1250px) {
  .sidebar {
    width: 22rem;
  }
  /* .logo-div {
    height: 5rem;
  } */
}
@media (max-width: 1182px) {
  .sidebar {
    width: 22rem;
  }
}
@media (max-width: 1088px) {
  .sidebar {
    width: 22rem;
  }
}
@media (max-width: 1034px) {
  .sidebar {
    width: 22rem;
  }
}
@media (max-width: 666px) {
  .sidebar {
    width: 7rem;
  }
}
@media (max-width: 572px) {
  .sidebar {
    width: 5rem;
  }
}
@media (max-width: 130px) {
  .sidebar {
    width: 2rem;
  }
}
