.cardProductDesription {
  color: #000;
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.editiconProductDescription {
  margin-bottom: 1.063rem;
  color: #26890d;
  margin-top: 0.938rem;
}

.saveIconProductDescription {
  margin-bottom: 1.063rem;
  color: #468529;
}

.lockIconProductDescription {
  margin-bottom: 1.063rem;
  color: grey;
}

.translateTooltipProductDescription {
  color: #26890d;
  margin-bottom: 0.625rem;
  margin-top: 0.063rem;
}
.submit-button-productDescription{
  font-weight: 600;
  display: flex;
  width: 20%;
  height: 2.813rem;
  padding: 0.5rem 0.625rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
}