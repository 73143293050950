.chatButton {
  margin-top: -5rem;
}

.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.chatHeading {
  background-color: green;
  display: flex;
  justify-content: space-between;
}

.cancelButton {
  margin-right: 2px;
}

.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */

.form-container .btn {
  background-color: #04aa6d;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.underline {
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s all;
}

.underline:hover {
  transform: scale(1.09);
}

.underline.clicked {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: green;
}

.underline:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.material-symbols-outlined {
  cursor: pointer;
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 1px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-detect {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid black;
  color: black;
  padding: 2px;
  top: -8px;
  left: 65%;
  transform: translateX(-50%);
}

.underline {
  cursor: pointer;
}

input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 0.15em solid #15803d;
  border-radius: 100%;
  background: white;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #15803d;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

input[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* .radioButton {
  color: #15803d;
  margin: 0.5rem;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #15803d;
} */

/* input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0.5rem;
  font: inherit;
  color: #15803d;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #15803d;
  border-radius: 50%;
  display: grid;
  place-content: center;
  position: relative;
}

input[type="radio"]:focus {
  outline: none;
  box-shadow: none;
}

input[type="radio"]:checked {
  background-image: none;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 1);
} */

.upload-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cccccc;
  padding: 20px;
  border: 2px solid black;
  /* Changed to dotted border */
  height: 200px;
  /* Set height */
  width: 500px;
  /* Set width */
  /* text-align: center; */
  /* Align text in the center */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.hidden {
  display: none;
}

.highlight {
  border: 2px dashed #666;
}
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 0.15em solid #15803d;
  border-radius: 100%;
  background: white;
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #15803d;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

input[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.translation-word-wrap{
  word-wrap: break-word;
}