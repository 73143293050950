.multiple-signals {
  width: 100%;
  justify-content: left;
  align-items: left;
}

.field-container {
  position: relative;
}

.text-field {
  position: relative;
  /* margin: 1rem; */
  margin-bottom: 0;
  width: 100%;
}

.delete-icon-box {
  margin-left: 1rem;
}

.delete-icon-box-mt-error {
  margin-top: -3.2rem;
  margin-bottom: 1.25rem;
}

.delete-icon-box-mt-no-error {
  margin-top: -1.2rem;
  margin-bottom: 2.25rem;
}

.add-button {
  display: flex;
  cursor: pointer;
  color: #26890d;
  background: #fff;
  width: fit-content;
  padding: 0.125rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.125rem;
  margin-left: auto;
}

.add-button:hover {
  opacity: 0.6;
}

.add-button-disabled {
  display: none;
}
