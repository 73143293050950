/* styles.css */

.gen_fill_container {
  height: 100%;
  width: 100%;
  padding-top: 1.563rem;
  padding-left: 6.25rem;
}

.prompt_container {
  width: 94%;
  margin-top: 1.563rem;
  display: flex;
}

.prompt_text {
  border: 0.063rem solid #c9c9c9;
  padding: 1.25rem;
  border-radius: 0.625rem;
  font-size: 1rem;
}

.prompt_btns {
  margin-left: 0.938rem;
  margin-top: 1.063rem;
  display: flex;
}

.generate_fill_container {
  width: calc(94% - 7.5rem);
  display: flex;
  justify-content: flex-end;
}

.generate_fill_btn {
  background: #26890d;
  color: white;
  padding: 0.5rem 0.625rem;
  border-radius: 6px;
  display: inline-block;
  margin-top: 1.438rem;
  cursor: pointer;
}

.add_more_btn {
  color: #26890d;
  display: flex;
  cursor: pointer;
}
