.duration{
    margin-left: 1rem;
    color : #000;
    font-weight: 400;
    font-size: 0.75rem;
    margin-right: 1rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;

}

.scene{
    color: #000;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 0.5rem;

}

.submit{
    padding-left: 3rem;
    padding-right: 3rem;
    font-weight: 600;
    height:3rem;
    border-radius:0.375rem;

}

.home{
    padding: 0.75rem 0.8125rem;
    font-weight: 600;
    border: 1px solid #26890D;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;

}

.textScene{
    font-weight: 600;
    width: 100%;

}

.vector{
    height: 1.4375rem;
    width: 1.4375rem;

}