@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

*::-webkit-scrollbar:horizontal{
  height:0.2em;
  
}

*::-webkit-scrollbar:vertical{
  width:0.2em;
  
}

*::-webkit-scrollbar-thumb{
  background:transparent !important;
  transition: background 2s ease;
 
}
*:hover::-webkit-scrollbar-thumb{
  background:#888 !important;
  
}
*::-webkit-scrollbar-track{
  background: transparent !important;
  transition: background 2s ease;

}
*:hover::-webkit-scrollbar-track{
  background: #f1f1f1 !important;
  
}

