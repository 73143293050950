.myContainerClass {
    border-radius: 1.5rem;
    width: 91.25rem;
    height: 40.625rem;
    background-color: #F2F2F2;
}

.rightBox {
    border-radius: 1rem;
    background-color: #fff;
    width: 31.5625rem;
    height: 35rem;
}

.imgComponent {
    color: #26890D;
    font-size: 80px;
    width: 80px;
    height: 80px;
    margin-bottom: 1.88rem;
}