.Arrow_container {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #26890d;
}
.genFillDisabled {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cccccc;
  color: #666666;
}
